<template>
     <div class="bg-blue-top-curve-desktop"></div>
     <div class="bg-blue">
      <div class="mobile-curve">

  <div class="container">
    <div class="row">
      <div class="col-sm-12 mb-5">      
        <PageLoader class="mb-5" v-show="!isLoading"></PageLoader>
        <section class="text-center" v-show="isLoading">          
          <div id="thank_you_text" v-if="display_thankyou">
            <h2 class="text-uppercase fw-900 text-pg-blue mb-3">THANK YOU FOR YOUR RECEIPT !</h2>
            <h5 class="text-pg-blue fw-bold h5 mb-4">We have received your submission.<br>
              Please allow 72 hours for receipt processing.</h5> 
              <router-link
            class="btn-link mb-4"
            :to="{ name: 'Home' }"
          >SEE MORE PROMOTIONS</router-link><br>
          <p>Click here to view the program<br> <a :href="selectedpromotion?.termslink" target="_blank">Terms and Conditions</a>.</p>    
          </div>
          <div id="upload_title" v-if="!needs_registeration && !display_thankyou">
            <h2 class="text-uppercase fw-900 text-pg-blue mb-3">UPLOAD YOUR RECEIPT</h2>            
          </div>
          <snipp-registration
            id="snipp-registration"
            v-if="!display_thankyou"
            :campaign-id="selectedpromotion?.promoId"
            include-continue-button="false"
            custom-css="https://snippcheck.blob.core.windows.net/files/PGLocalValues/snipp_widget.css"
             @snipp-widget-event="handleWidgetEvent"
          ></snipp-registration>
          <div id="upload_desc" v-if="!needs_registeration && !display_thankyou">
            <p>Please note: <br>ONLY JPEG, PNG & PDF file formats are accepted. <br>Maximum of 6MB per photo/proof of purchase
              record.</p>           
          </div>
        </section>
      </div>
    </div>
  </div> 
  </div>
  </div>
</template>

<script>
import PageLoader from '../components/UI/PageLoader.vue';
import jsonMixin from '../jsonMixin';
export default {
  components: { PageLoader },
  mixins: [jsonMixin],
  props: ['id'],
  data() {
    return { selectedpromotion: null,
       isLoading: false,
        display_thankyou: false,
        registered:false,
        needs_registeration:false};
  },


  methods: {
    handleWidgetEvent(e) {
        console.log("handleWidgetEvent: e: ", e);
        let this_page = 'Redeem';
        let next_page;
        if (e && e.detail && e.detail.length) {
          let message = e.detail[0];
          if (message.is_registered) {
            this.needs_registeration = false;
          }
          if (message.is_logged_out) {
            this.needs_registeration = true;
          }
          if (message.uppy_close) next_page = 'Redeem';
          if (message.upload_result) {
            this.display_thankyou = true;
          }          
        }
        if (this_page && next_page && this_page != next_page) {
          let url = location.href;
          url = url.replace(this_page, next_page);
          location.href = url;
        }
      }
  },
  beforeMount() {
    setTimeout(() => (this.isLoading = true), 2000);
  },
};

</script>

<style scoped>
.campaigndiv {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 650px;
  margin: 0 auto;
}
.campaignbox img {
  max-width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-bottom: 20px; border: 5px solid #16a2dd;
}
@media screen and (max-width: 575px) {
  .mobile-curve {
        margin-top: 25px;
    }
  .mobile-curve::after{ display: none;}
  .campaigndiv {text-align: center;}
}
</style>
