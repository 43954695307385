<template>
  <div class="container">
    <div class="row">
      <PromoItem
        v-for="promo in promotions"
        :key="promo.id"
        :id="promo.id"
        :promoname="promo.name"
         :promoimg="promo.img"
        :promodesc="promo.desc"
        :promoId="promo.promoId"
        :promostartdate="promo.startdate"
        :promoenddate="promo.enddate"
        :promoIsActive="promo.isActive"
        @viewDetails="goToDetails(promo.id)"
      ></PromoItem>
    </div>
  </div>
  
   <!-- Loop over the disclaimers -->
   <!-- <div class="container">
    <small v-for="promo in promotions" :key="promo.id">
      <span v-html=" promo.disclaimer "></span> <br><br>
    </small>
  </div> -->
</template>

<script>
import jsonMixin from '../../jsonMixin'
import PromoItem from './PromoItem.vue';

export default {
  components: { PromoItem },
  mixins: [jsonMixin],
};
</script>

<style scoped></style>
