<template>
  <footer class="py-3 px-5">
    <div class="container">
      <div class="row">
        <div class="col-md-2 text-center">
          <router-link to="/Home" class="navbar-brand"><img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/pg.logo.svg"
            class="img-fluid footerlogo"
            alt="P&G logo"
        /></router-link>    
      
        </div>
        <div class="col-md-10 text-left">
          <!-- <p>
            Shop your favorite P&G brands at your local grocer to unlock reward
            opportunities.
          </p> -->
          <div class="footer-left col p-0">
            <ul class="list-inline mb-2" id="footer-nav">
              <li class="list-inline-item p">
                <a
                  href="javascript:void(0)"
                  onclick="Optanon.ToggleInfoDisplay();"
                  >Do Not Sell or Share My Personal Information / Opt-Out of
                  Targeted Advertising</a
                >
              </li>
              <li class="list-inline-item">
                <a
                  rel="noopener noreferrer"
                  href="http://www.pg.com/en_US/terms_conditions/index.shtml"
                  target="_blank"
                  >Terms & Conditions</a
                >
              </li>
              <li class="list-inline-item">
                <a
                  href="http://www.pg.com/en_US/contact_us/index.shtml"
                  target="_blank"
                  >Contact Us</a
                >
              </li>
              <li class="list-inline-item">
                <a
                  rel="noopener noreferrer"
                  href="https://privacypolicy.pg.com/en/#statePrivacyNotice"
                  target="_blank"
                  >Privacy</a
                >
              </li>
              <li class="list-inline-item">
                <a href="https://privacypolicy.pg.com/CHD-en" target="_blank"
                  >Consumer Health Data Privacy Policy</a
                >
              </li>
              <li class="list-inline-item">
                <a href="https://preferencecenter.pg.com/en-us/" target="_blank"
                  >Your Privacy Choices <img src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/privacyoptions.png" alt="Your Privacy Choices" class="img-fluid"></a
                >
              </li>
            </ul>
            <p><small>NOTICE: We may sell your sensitive personal data.</small></p>
            <p>
              <small
                >© {{ new Date().getFullYear() }} Procter & Gamble. All rights
                reserved. </small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  width: 100%;
  background-color: #012169;
  color: #fff;
}
.footerlogo {
  max-width: 80px;
  margin-bottom: 20px;
}
a {
  font: inherit;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  padding-right: 0.5rem;
  margin: 5px 0;
  display: inline-block;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
#footer-nav li + li:before {
    content: " | ";
    color: #fff;
    margin-right: 7px;
}
@media screen and (max-width: 575px) {
  .list-inline-item {
    display: block;
}
  #footer-nav li + li:before {
display: none;
}
  footer {
    text-align: center;
  }
}
</style>
