import { createApp } from 'vue';

import router from './router.js';
import 'bootstrap/dist/css/bootstrap.css';
import '../public/assets/css/main.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

import App from './App.vue';

const app = createApp(App);

app.use(router);
app.use(bootstrap);
app.mount('#app');
