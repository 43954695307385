<template>
  <div class="campaigndiv col-sm-6" v-if="promoIsActive == 1">
    <div class="text-center campaignbox">
      <img
        :src="promoimg"
        class="img-fluid rounded-circle"
        alt="Banner"
      />
      <h4 class="fw-bold text-pg-blue mb-3">
        {{ promoname }}
      </h4>
      <p class="promodesc" v-html="promodesc"></p>
      <!-- <p>{{ formattedStartDate }} - {{ formattedEndDate }}</p> -->
      <div v-if="!ProgramMessage">
        <router-link
          class="btn-link"
          :to="{ name: 'Promotions', params: { id: id } }"
        >
          PROMO DETAILS
        </router-link>
        <br />
        <!-- <router-link
          class="mt-2 d-inline-block"
          :to="{ name: 'Promotion Redeem', params: { id: id } }"
        >
          Redeem
        </router-link> -->
      </div>
      <small>
        <b>{{ ProgramMessage }}</b>
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, required: true },
    promoname: { type: String, required: true },
    promoimg: { type: String, required: true },
    promodesc: { type: String, required: true },
    promoId: { type: String, required: true },
    promostartdate: { type: String, required: true },
    promoenddate: { type: String, required: true },
    promoIsActive: { type: String, required: true },
  },
  data() {
    return {
      ProgramMessage: '',
    };
  },
  computed: {
    formattedStartDate() {
      try {
        const usTimezoneOptions = { timeZone: 'America/New_York' };
        const date = new Date(this.promostartdate);
        if (isNaN(date)) {
          throw new Error('Invalid date format');
        }
        return date.toLocaleDateString('en-US', usTimezoneOptions);
      } catch (error) {
        console.error('Start date error:', error);
        return 'Invalid start date';
      }
    },
    formattedEndDate() {
      try {
        const usTimezoneOptions = { timeZone: 'America/New_York' };
        const date = new Date(this.promoenddate);
        if (isNaN(date)) {
          throw new Error('Invalid date format');
        }
        return date.toLocaleDateString('en-US', usTimezoneOptions);
      } catch (error) {
        console.error('End date error:', error);
        return 'Invalid end date';
      }
    },
  },
  methods: {
    filterCampaigns() {
      try {
        const usTimezoneOptions = { timeZone: 'America/New_York' };

        const currentDate = new Date().toLocaleDateString('en-US', usTimezoneOptions);
        const promoStartDate = new Date(this.promostartdate).toLocaleDateString('en-US', usTimezoneOptions);
        const promoEndDate = new Date(this.promoenddate).toLocaleDateString('en-US', usTimezoneOptions);

        if (isNaN(new Date(currentDate)) || isNaN(new Date(promoStartDate)) || isNaN(new Date(promoEndDate))) {
          throw new Error('Invalid date format');
        }

        if (new Date(currentDate) < new Date(promoStartDate)) {
          this.ProgramMessage =
            'We’re sorry, but this program hasn’t started yet. Please check back on ' +
            promoStartDate;
        } else if (new Date(currentDate) > new Date(promoEndDate)) {
          this.ProgramMessage = 'We’re sorry, but this program has ended.';
        }
      } catch (error) {
        console.error('Date error:', error);
        this.ProgramMessage = 'An error occurred while processing the program dates. Please try again later.';
      }
    },
  },
  mounted() {
    this.filterCampaigns();
  },
};
</script>

<style scoped>
.campaigndiv {
  margin: 0 auto;
}

.campaignbox {
  margin: 20px;
  text-align: center;
  padding: 30px;
}

.campaignbox img {
  max-width: 160px;
  height: 160px;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 5px solid #16a2dd;
}
.promodesc{min-height: 70px;}
@media screen and (max-width: 575px) {
  .campaignbox {
    width: 93%;
    padding: 20px;
  }
}
</style>
