<template>
    <div class="video-container">
      <video controls width="320" height="240" poster="/public/assets/images/videothumbnail.png">
        <source :src="videoSrc" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </template>
  
  <script>
  export default {
    name: "VideoPlayer",
    props: {
      videoSrc: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .video-container {
    max-width: 100%;
    height: auto;
  }
  video {
    width: 100%;
    height: auto;
  }
  </style>
  