export default {
  data() {
    return { promotions: [] };
  },
  methods: {
    async getJsonData() {
      try {
        const response = await fetch('/Campaigns.json');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        this.promotions = data.promotions;
        console.log('Fetched promotions:', this.promotions);
        this.updateSelectedPromotion();
      } catch (error) {
        console.error('Unable to fetch data:', error);
      }
    },
    updateSelectedPromotion() {
      if (this.id) {
        this.selectedpromotion = this.promotions.find(promo => promo.id === this.id);
        console.log('Selected promotion:', this.selectedpromotion);
      }
    }
  },
  mounted() {
    this.getJsonData();
  },
};
