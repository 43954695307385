<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid align-items-md-start align-items-sm-center"> 
        <router-link to="/Home" class="navbar-brand"><img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/pg.logo.svg"
            class="img-fluid"
            alt="P&G logo"
        /></router-link>     
        
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse navigation"
          id="navbarSupportedContent"
        >
          <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/logo_desktop.png"
            alt="" role="none" aria-hidden="true"
            class="img-fluid"
          />
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/Home" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item d-none">
              <router-link to="/HowItWorks" class="nav-link"
                >How It Works</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/Faq" class="nav-link">FAQ</router-link>
            </li>
          </ul>
        </div>
        <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/logo_desktop.png" alt="" role="none" aria-hidden="true"
            class="img-fluid d-md-none"
          />
      </div>
    </nav>

    <!-- <img
        src="../../../public/assets/images/logo_desktop.png"
        class="navheaderimg"
        alt="Desktop Logo"
      /> -->
  </header>
</template>

<script>
export default {};
</script>

<style scoped>
header {
  width: 100%;
}
.navbar {
  padding: 0;
}
.nav {
  height: 100%;
  text-align: center;
}
.navigation {
  margin: 0 auto !important;
  position: relative;
  margin-left: -70px !important;
}
.navigation ul {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  list-style: none;
  margin: 0;
  padding: 0;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.navigation ul li {
  padding: 25px 10px;
}
.navigation img {
  max-width: 500px;
  margin: 0 auto;
}

.navbar-brand {
  max-width: 50px;
    margin: 30px 20px;
    z-index: 999;
}
.nav-link {
  background: transparent;
  cursor: pointer;
  color: white;
  display: inline-block;
  text-decoration: none;font-size: 1.2rem;
  border-bottom: 2px solid transparent;
}

.nav-link:hover,
.nav-link:active,
.nav-link.active {
  color: white;
  border-bottom: 2px solid #97d700;
}
@media screen and (max-width: 575px) {
.navbar-toggler-icon {
    display: inline-block;
    width: 2em;
    height: 1.5em;}
  .navigation {
    margin-left: 0px !important;
        position: absolute;
        width: 100%;
        top: 114px;
        left: 0;
        right: 0;
  }
  .navbar-toggler{border: none;box-shadow: none;}
  .navigation img {
    max-width: 100%;display: none;
  }
  ul.navbar-nav {
    display: inline;
    margin: 0 auto;
    text-align: center;
  }
  ul.navbar-nav li {
    display: inline-block;
  }
}
</style>
