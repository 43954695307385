<template>
  <div class="container">
    <div class="row faqs mx-auto py-5">
      <div class="col-md-12">
        <h1 class="text-center text-pg-blue fw-bold mb-5">
        Frequently Asked Questions
        </h1>
      </div>
      <div class="faqwrapper">
      <!-- <div class="faqdiv mb-4">
        <h6 class="pb-2 fw-bold">
          Can I shop anywhere P&G products are found?
        </h6>
        <div class="copy ">
          <p>
            P&G Local Values promotions only apply to participating
stores. <a href="/">Verify a grocery store in your area</a> and then shop the
participating products to successfully redeem your reward.
          </p>
        </div>
      </div> -->
      <div class="faqdiv mb-4">
        <h6 class="pb-2 fw-bold">
          How will I get my reward?
        </h6>
        <div class="copy">
          <p>
            If found to be valid, you will receive either a digital gift card or physical gift card within 6-8 weeks when the program ends.
          </p>
        </div>
      </div>
      <div class="faqdiv mb-4">
        <h6 class="pb-2 fw-bold">
          Can I participate in more than one promotion?
        </h6>
        <div class="copy">
          <p>
            You can participate in multiple promotions as long as you
have a unique qualifying receipt for each promotion. Each
purchase receipt is eligible for one promotion submission
only. Review individual promotion terms and conditions for
more information.
          </p>
        </div>
      </div>
      <div class="faqdiv mb-4">
        <h6 class="pb-2 fw-bold">
          Do I have to upload a picture of my receipt to redeem
my reward?
        </h6>
        <div class="copy">
          <p>
            To submit online you will need to upload a picture of your receipt.
          </p>
        </div>
      </div>
      <div class="faqdiv mb-4">
        <h6 class="pb-2 fw-bold">I do most of my shopping online. Are online
          purchases valid?</h6>
        <div class="copy">
          <p>
            Yes! Online purchase receipts are valid as long as the
purchase is made at one of the promotion's participating
grocery stores.
          </p>
        </div>
      </div>
      <div class="faqdiv mb-4">
        <h6 class="pb-2 fw-bold"> I don’t see any participating groceries in my area. Now what?
          Now what?</h6>
        <div class="copy">
          <p>
            It's possible that there aren't any grocery stores in your area participating in one of our promotions at this time. Promotions are added throughout the year, so bookmark this page and check back later.
          </p>
        </div>
      </div>
      <div class="faqdiv mb-4">
        <h6 class="pb-2 fw-bold">
          Can I enter a promotion multiple times?
        </h6>
        <div class="copy">
          <p>
            Not at this time. P&G Local Values programs only accept
one qualifying receipt per person, per promotion.
          </p>
        </div>
      </div>
      <div class="faqdiv mb-4 d-none">
        <h6 class="pb-2 fw-bold">
          I don't see any live promotions in my area. Now what?
        </h6>
        <div class="copy">
          <p>
            It's possible that there aren't any grocery stores in your area
            participating in one of our promotions at this time. Promotions are
            added throughout the year, so bookmark this page and check back
            later.
          </p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqItem',
  // inject: ['faqs'],
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 2rem auto;
  padding: 0;
}
li {
  margin: 1rem 0;
  border: 1px solid #ccc;
  padding: 1rem;
}

h3 {
  margin: 0.5rem 0;
}
.faqs .copy p {
  font-size: 1rem;
}
</style>
