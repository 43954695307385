<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4 text-center">
        <div class="htwstep">
          <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/step1.png"
            alt="shop"
            class="img-fluid"
          />
          <h3 class="fw-bold text-pg-blue mb-3 h5">Shop participating P&G
products on your next
grocery trip.</h3>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="htwstep">
          <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/step2.png"
            alt="upload"
            class="img-fluid"
          />
          <h3 class="fw-bold text-pg-blue mb-3 h5">Return here & upload
            your receipt.</h3>
         
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="htwstep">
          <img
            src="https://snippcheck.blob.core.windows.net/files/PGLocalValues/step3.png"
            alt="send"
            class="img-fluid"
          />
          <h3 class="fw-bold text-pg-blue mb-3 h5">Sit back, relax and get
            a reward!</h3>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowitworksSteps',
};
</script>

