<template>
  <the-navigation></the-navigation>
  <main>
    <router-view></router-view>
  </main>
  <the-Footer></the-Footer>
</template>

<script>
import TheNavigation from './components/Layout/TheNavigation.vue';
import TheFooter from './components/Layout/TheFooter.vue';
export default {
  components: {
    TheNavigation,
    TheFooter,
  },
  mounted() {
    var scripts = [
      'https://snipp-it-widgets.s3.us-west-2.amazonaws.com/SnippWebsocket/snipp-websocket.umd.js',
      'https://snipp-it-widgets.s3.us-west-2.amazonaws.com/SnippCurrentUser/snipp-current-user.umd.js',
      'https://snipp-it-widgets.s3.us-west-2.amazonaws.com/SnippUpload/snipp-upload.umd.js',
      'https://snipp-it-widgets.s3.us-west-2.amazonaws.com/SnippRegistration/snipp-registration.umd.js',
    ];

    for (var i = 0; i < scripts.length; i++) {
      var script = document.createElement('script');
      script.onerror = function () {
        alert('Could not load ' + this.src);
      };
      script.src = scripts[i] + '?ts=' + Date.now();
      document.body.appendChild(script);
    }
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}
</style>
