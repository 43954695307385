import { createRouter, createWebHistory } from 'vue-router';

import Home from './Pages/Home/HomePage.vue';
// import HowItWorks from './Pages/HowItWorks/HowItWorks.vue';
import FaqList from './Pages/Faq/FaqList.vue';
import Promodetail from './Pages/PromoDetail.vue';
import PromoRedeem from './Pages/PromoRedeem.vue';

const router = createRouter({
  linkActiveClass: 'active',
  root: '/Home',
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/Home',
      name: 'Home',
      component: Home, // our.Domain/Home
      meta: { title: 'Home' },
    },
    {
      path: '/:id',
      name: 'Promotions',
      component: Promodetail,
      props: true,
      meta: {  },
    },
    {
      path: '/:id/Redeem',
      name: 'Promotion Redeem',
      component: PromoRedeem,
      props: true,
      meta: { title: '', requiresAuth: true },
    },
    // {
    //   path: '/Howitworks',
    //   name: 'HowItWorks',
    //   component: HowItWorks, // our.domain/Home
    //   meta: { title: 'How it Works' },
    // },
    {
      path: '/Faq',
      name: 'Faq',
      component: FaqList, // our.domain/Faq
      meta: { title: 'Faqs' },
    },
    {
      path: '/Home',
      component: Home, // our.domain
    },
    { path: '/:notFound(.*)', component: Home },
    { path: '/', redirect: '/Home' },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // Set the document title
  let documenttitle = `${to.meta.title ? to.meta.title + ' |' : ''} P&G Local Values`;
  if (to.params.id) {
    documenttitle = `P&G Local Values - ${to.params.id}`;
  }
  document.title = documenttitle;

  // Check if the route requires authentication
  if (to.meta.requiresAuth && !from.name) {
    // If trying to access a restricted route directly, redirect to Home
    next({ name: 'Home' });
  } else {
    next(); // Allow navigation
  }
});

export default router;