<template>
  <div class="bg-blue-top-curve-desktop"></div>   
  <div class="bg-blue">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-5">
        <h1 class="text-uppercase text-center text-pg-blue fw-900 ">
          <p class="text-center">
            Welcome to<br />
            P&amp;G Local Values<span class="text-pg-green">.</span>
          </p>
        </h1>
        <p class="text-center fw-bold text-pg-blue">
          P&G Local Values is your destination for earning more when you
          <br />shop trusted P&G brands at your local grocer. Find store
          <br />promotions in your area, upload your receipt and earn.
        </p>
        
      </div>
    </div>
  </div>
  <div class="mobile-curve">
    <div class="container">
    <div class="row">
      <div class="col-md-12"></div>
    <h2 class="mb-3 h2 fw-bold text-pg-blue text-center ">
          Shop<span class="">.</span> Redeem<span class="">.</span>
          Earn<span class="">.</span>
        </h2>
        </div>  </div> 
  <HowitworksSteps></HowitworksSteps>
  </div>
  <PromoList></PromoList>
  <div class="container">
  <div class="col-sm-12 mb-5">
      <small>**Use your Prepaid Mastercard anywhere Mastercard is accepted around the world. The Card is issued by The Bancorp Bank, Member FDIC, pursuant to a license from Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.<br><br>
        **Virtual Visa Prepaid Card is issued by Pathward<sup>®</sup>, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Can be used everywhere Visa debit cards are accepted online, or phone/mail orders. Valid for up to 6 months; unused funds will forfeit after the valid thru date. Terms and conditions apply.
      </small>
    </div>
  </div>
  <div class="video-wrap mt-5 pb-5" id="htp">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">          
          <VideoPlayer videoSrc="https://snippcheck.blob.core.windows.net/files/PGLocalValues/How-To-Submit-Final.mp4#t=0.001" />
          <p class="text-center">Click Here to learn how to submit.</p>
        </div>
      </div>
      
    </div>
   
  </div>

  </div>
</template>
<script>
import PromoList from '../../components/PromoList/PromoList.vue';
import HowitworksSteps from '../HowItWorks/HowitworksSteps.vue';
import VideoPlayer from './VideoPlayer.vue';

export default {
  title: 'Foo Page',
  components: {
    PromoList,
    HowitworksSteps,
    VideoPlayer,
  },
};
</script>

<style scoped>

</style>
